<template>
    <div class="relative">
      <div class="w-full absolute block z-50">
        <Header/>
      </div>
      <div class="w-screen section-container space-top lavish-banner">
        <div class="relative h-full w-full flex xl:items-end items-center justify-center flex-col">
          <img src="../../assets/images/faqs-page/lavish-header.png" alt="Lavish Lashes Banner"
          class="absolute top-0 left-0 h-full z-5 -ml-40 object-cover overflow-x-visible mobileL:-ml-64 lg:-ml-36 xl:ml-40 xl:pl-1">
          <div class="items-center flex-col z-10 text-center mobileL:ml-0 xl:mr-16 2xl:mr-36 ">
            <h1 class="font-historia text-center text-4xl md:text-6xl 2xl:text-8xl
            text-white-1 leading-none mt-10 2xl:mt-16 tracking-wide">
              The <span class="italic text-yellow-2">best lashes</span><br> in town!
            </h1>
          </div>
        </div>
      </div>
      <div class="bg-yellow-2 py-8 lg:py-12 xl:py-32 text-white-1">
        <div class="flex-row">
            <div class="mx-auto w-5/6 flex-row mobileL:flex mobileL:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl text-pink-17
            font-futura leading-none items-center font-semibold tracking-wide">
              <div class="flex-1 text-center flex flex-col items-center mobileL:mr-12 lg:mr-20 gap-6 mobileL:flex">
                  <img class="w-40 mobileL:w-80" src="../../assets/images/faqs-page/lash-aftercare.png" alt="1">
              </div>
              <div class="grid grid-cols-2 gap-6 mobileL:grid mobileL:grid-cols-4 mobileL:gap-4 lg:gap-8 xl:gap-16 2xl:gap-24 
              mobileL:mr-10 lg:mr-20">
                <div class="flex-1 text-center flex flex-col items-center pt-10">
                    <img class="w-8 mobileL:w-16 xl:w-20 mb-4" src="../../assets/images/faqs-page/eyelash.png" alt="2">
                    <p class="mt-2 mobileL:mt-3 xl:mt-2 mb-6 mobileL:mb-0">Skip oil-based <br>products for the <br>meantime</p>
                </div>
                <div class="flex-1 text-center flex flex-col items-center pt-10">
                    <img class="w-8 mobileL:w-16 xl:w-20 mb-4" src="../../assets/images/faqs-page/eyelash.png" alt="2">
                    <p class="mt-2 mobileL:mt-3 xl:mt-2 mb-6 mobileL:mb-0">Avoid rubbing <br>your eyes</p>
                </div>
                <div class="flex-1 text-center flex flex-col items-center pt-10">
                    <img class="w-8 mobileL:w-16 xl:w-20 mb-4" src="../../assets/images/faqs-page/eyelash.png" alt="2">
                    <p class="mt-2 mobileL:mt-3 xl:mt-2 mb-6 mobileL:mb-0">Do not use <br>mascara</p>
                </div>
                <div class="flex-1 text-center flex flex-col items-center pt-10">
                    <img class="w-8 mobileL:w-16 xl:w-20 mb-4" src="../../assets/images/faqs-page/eyelash.png" alt="2">
                    <p class="mt-2 mobileL:mt-3 xl:mt-2 mb-6 mobileL:mb-0">Always keep <br>them clean</p>
                </div>
              </div>  
            </div>
        </div>
      </div>
      <div class="bg-pink-18">
        <h2 class="text-white-1 text-center mobileL:py-4 py-3 font-futura font-semibold text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl tracking-wider">
            Lash sins to avoid
        </h2>
      </div>
      <div class="bg-white-1 py-12 mobileL:py-8 lg:py-12 text-white-1">
        <div class="flex-row">
            <div class="mx-auto w-5/6 2xl:gap-10 md:gap-12 flex justify-between flex-wrap gap-16 md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl text-black-3
            font-futura font-semibold leading-none items-center tracking-wider">
                <div class="flex-1 text-center flex flex-col items-center lash-sin-bg mobileL:py-10 xl:pb-20 xl:pt-20">
                    <img class="w-8 mobileL:w-16 xl:w-20 mb-4" src="../../assets/images/faqs-page/eyelash.png" alt="2">
                    <p class="mt-2 mobileL:mt-4 xl:mt-8">Picking your lash<br>extensions</p>
                </div>
                <div class="flex-1 text-center flex flex-col items-center lash-sin-bg mobileL:py-10 xl:pb-20 xl:pt-20">
                    <img class="w-8 mobileL:w-16 xl:w-20 mb-4" src="../../assets/images/faqs-page/eyelash.png" alt="2">
                    <p class="mt-2 mobileL:mt-4 xl:mt-8">Sleeping face<br>down</p>
                </div>
                <div class="flex-1 text-center flex flex-col items-center lash-sin-bg mobileL:py-10 xl:pb-20 xl:pt-20">
                    <img class="w-8 mobileL:w-16 xl:w-20 mb-4" src="../../assets/images/faqs-page/eyelash.png" alt="2">
                    <p class="mt-2 mobileL:mt-4 xl:mt-8">Using eyelash<br>curler</p>
                </div>
                <div class="flex-1 text-center flex flex-col items-center lash-sin-bg mobileL:py-10 xl:pb-12 xl:pt-12">
                    <img class="w-8 mobileL:w-16 xl:w-20 mb-4 mt-8" src="../../assets/images/faqs-page/eyelash.png" alt="2">
                    <p class="mt-2 mobileL:mt-4 xl:mt-8">Applying mascara<br>on top of your<br>extensions</p>
                </div>
                <div class="flex-1 text-center flex flex-col items-center lash-sin-bg mobileL:py-10 xl:pb-12 xl:pt-12">
                    <img class="w-8 mobileL:w-16 xl:w-20 mb-4 mt-8" src="../../assets/images/faqs-page/eyelash.png" alt="2">
                    <p class="mt-2 mobileL:mt-4 xl:mt-8">Exposure to<br>excessive heat or<br>steam</p>
                </div>
            </div>
        </div>
      </div>
      <div class="relative">
        <div class="relative flex items-center justify-center bg-magenta-5">
          <div class="w-1/2 mobileL:w-2/5 text-base md:text-lg lg:text-xl xl:text-4xl 2xl:text-6xl flex-1 text-center flex flex-col 
          items-center pt-8 mobileL:pt-28">
            <img class="w-6 mobileL:w-16 xl:w-20" src="../../assets/images/faqs-page/shine-pink.png" alt="shine">
            <p class="leading-snug text-pink-17 font-futura font-semibold tracking-wider">
              With proper care, your<br>
              lash extensions can last<br>
              up to 2-3 weeks<br>
            </p>
          </div>
          <div class="w-1/2 mobileL:w-7/12">
            <h3 class="absolute bottom-2 mobileL:bottom-0 justify-center p-4 mobileL:p-8 xl:p-12 font-futura text-3xl mobileL:text-4xl  
            lg:text-6xl 2xl:text-9xl text-white-1 leading-none font-semibold tracking-wider">
              How long <br>do lash <br>extensions <br>last?
            </h3>
            <img class="h-52 mobileL:h-full object-cover" src="../../assets/images/faqs-page/how-long-banner.jpg">
          </div>
        </div>
      </div>
      <div class="relative w-full">
        <div class="absolute inset-0 flex justify-center items-center">
          <div class="text-white-1 text-center font-futura mobileL:w-4/6">
            <div class="text-center w-full">
              <h2 class="text-xl md:text-2xl lg:text-4xl 2xl:text-6xl mb-2 mobileL:mb-4 lg:mb-6 font-semibold">
                How many lashes are there in a full set?
              </h2>
            </div>
            <div class="mx-auto flex flex-row text-sm gap-2
            md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl justify-center items-center">
              <div class="flex-1 text-center flex flex-col items-center mb-2 mobileL:mb-6 cursor-default">
                <h4 class="mobileL:mb-2">Fancy Look</h4>
                <p class="border-solid border-2 px-2 mobileL:px-10 py-0.5 mobileL:py-2 rounded-lg mobileL:rounded-2xl">45 per eye</p>
              </div>
              <div class="flex-1 text-center flex flex-col items-center mb-2 mobileL:mb-6 cursor-default">
                <h4 class="mobileL:mb-2">Glamour Look</h4>
                <p class="border-solid border-2 px-2 mobileL:px-10 py-0.5 mobileL:py-2 rounded-lg mobileL:rounded-2xl">75 per eye</p>
              </div>
              <div class="flex-1 text-center flex flex-col items-center mb-2 mobileL:mb-6 rounded-lg cursor-default">
                <h4 class="mobileL:mb-2 text-xs md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl">Extra Glamour Look</h4>
                <p class="border-solid border-2 px-2 mobileL:px-10 py-0.5 mobileL:py-2 rounded-lg mobileL:rounded-2xl">90 per eye</p>
              </div>
            </div>
            <div class="mt-2 lg:mt-4 xl:mt-6 flex w-2/3 items-center justify-center mx-auto text-sm gap-2
            md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl">
              <div class="flex-1 text-center flex flex-col items-center mb-2 mobileL:mb-6 cursor-default">
                <h4 class="mobileL:mb-2">Lavish Look</h4>
                <p class="border-solid border-2 px-2 mobileL:px-6  lg:px-10 py-0.5 mobileL:py-2 rounded-lg mobileL:rounded-2xl">110 per eye</p>
              </div>
              <div class="flex-1 text-center flex flex-col items-center mb-2 mobileL:mb-6 cursor-default">
                <h4 class="mobileL:mb-2">Ultra Lavish</h4>
                <p class="border-solid border-2 px-2 mobileL:px-6 lg:px-10 py-0.5 mobileL:py-2 rounded-lg mobileL:rounded-2xl">125 per eye</p>
              </div>
            </div>
          </div>
        </div>
        <img class="h-52 mobileL:h-full object-cover" src="../../assets/images/faqs-page/full-set-lashes-banner.jpg">
      </div>
      <div class="bg-yellow-2 py-8 mobileL:py-16 xl:py-20 text-white-1 relative">
        <div class="flex-row">
            <div class="mx-auto w-full gap-4 mobileL:gap-6 2xl:gap-4 flex flex-row text-xs mobileL:text-base lg:text-lg xl:text-2xl 2xl:text-3xl text-pink-17
            font-futura leading-none items-center font-bold tracking-wider">
                <div class="flex-1 z-10 text-center flex flex-col items-center mt-24 mobileL:-mt-10 xl:-mt-16">
                    <img class="w-6 mobileL:w-10 xl:w-20 mb-4" src="../../assets/images/faqs-page/eyelash.png">
                    <p class="mt-2 mobileL:mt-4 xl:mt-4">Book in advance<br>to avoid waiting</p>
                </div>
                <div class="flex-1 z-10 text-center flex flex-col items-center mt-36 mobileL:mt-6 xl:mt-12">
                    <img class="w-6 mobileL:w-10 xl:w-20 mb-4" src="../../assets/images/faqs-page/eyelash.png">
                    <p class="mt-2 mobileL:mt-4 xl:mt-4 tracking-widest">
                      Wear comfy and<br>cozy clothes since it'll<br>
                      take 40 minutes or<br>more depending on<br>
                      the lash style &<br> volume
                    </p>
                </div>
                <div class="flex-1 z-10 text-center flex flex-col items-center mt-32 mobileL:mt-0 xl:mt-4">
                    <img class="w-6 mobileL:w-10 xl:w-20 mb-4" src="../../assets/images/faqs-page/eyelash.png">
                    <p class="mt-2 mobileL:mt-4 xl:mt-4 tracking-widest">
                      It's best to arrive<br>with minimal to no<br>
                      makeup to keep<br>those lashes clean<br>
                      and long lasting
                    </p>
                </div>
                <div class="absolute mobileL:relative text-center flex flex-col items-center justify-center left-0 right-0 top-4 mobileL:mr-8 xl:mr-20">
                    <h3 class="absolute font-normal justify-center items-center font-historia text-2xl 
                      mobileL:text-3xl lg:text-4xl xl:text-6xl 2xl:text-8xl">
                      How to prep<br> for your lash <br>
                      extension <br> appointment <br>
                    </h3>
                    <div class="w-44 mobileL:w-52 lg:w-64 xl:w-100 2xl:w-120 right-10">
                      <img src="../../assets/images/faqs-page/shine-yellow.png">
                  </div>
                </div>
            </div>
        </div>
      </div>
      <div class="w-full absolute block z-20">
        <Footer/>
      </div>
    </div> 
</template>
  
<script>
import Header from '../../components/navigation/LavishHeader.vue'
import Footer from '../../components/footer/LavishFooter.vue'

export default {
  components: {
    Header,
    Footer
  },
}
</script>
  
  <style scoped>
  .section-container {
    height: 700px;
  }
  
  .space-top {
    margin-top: 90px;
  }
  
  @media (max-width: 480px) {
    .space-top {
      margin-top: 60px;
    }
    .section-container {
      height: 45vh;
      overflow: hidden;
    }
  }

  @media (min-width: 480px) {
    .lash-sin-bg {
    background: radial-gradient(#ED7A97 -0%, #fef6ef 75%);
    border-radius: 10px;
    }
  }
  
  .lavish-banner {
    background: url('../../assets/images/faqs-page/lavish-header-banner.jpg') no-repeat;
    background-size: 100% 100%;
    font-family: 'Kannada';
  }
  
  @media (min-width: 1280px) {
      .space-top {
        margin-top: 100px;
      }
      .section-container {
        height: calc(100vh - 100px);
      } 
  }
  
  @media (min-width: 1536px) {
      .space-top {
        margin-top: 128px;
      }
  
      .section-container {
        height: calc(100vh - 120px);
      }
  }
  </style>